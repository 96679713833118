<template>
    <EKDialog
        :isEdit="!!semesterDto.id"
        ref="dialog"
        :title="semesterDto.id ? 'تعديل  فصل' : 'اضافة فصل '"
        @close="resetForm"
        @ok="onSubmit()"
        @delete="deleteSem(semesterDto.id)"
    >
        <template slot="body">
            <ValidationObserver ref="observer">
                <EKInputText
                    :rules="[{ type: 'required', message: 'الترتيب مطلوب' }]"
                    label="الترتيب  "
                    type="number"

                    v-model="semesterDto.order"
                    placeholder="ادخل الترتيب"
                    name=" name"
                />
                <EKInputText
                    :rules="[{ type: 'required', message: 'اسم الفصل مطلوب' }]"
                    label="الفصل  "
                    v-model="semesterDto.name"
                    placeholder="ادخل اسم الفصل"
                    name=" name"
                />
                
                <div v-if="semesterDto.units && semesterDto.units.length">
                    <div
                        v-for="(unit, index) in semesterDto.units"
                        :key="index"
                    >
                        <EKInputText
                            label="اسم الوحدة"
                            v-model="unit.unitName"
                            name=" unitName"
                            readonly
                        />
                        <EKInputText
                            label="اسم المادة"
                            v-model="unit.subjectName"
                            name=" subjectName"
                            readonly
                        />
                        <EKInputText
                            label="عدد الاقسام"
                            v-model="unit.chaptersCount"
                            name="chaptersCount"
                            readonly
                        />
                    </div>
                </div>
            </ValidationObserver>
        </template>
    </EKDialog>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapState, mapActions } from "vuex";
export default {
    components: {
        ValidationObserver,
        EKDialog,
        EKInputText,
    },
    computed: {
        ...mapState({
            semesterDto: (state) => state.settings.semesterDto,
            isDialogOpen: (state) => state.settings.isDialogOpen,
        }),
    },
    methods: {
        ...mapActions([
            "modifySemester",
            "addSemester",
            "getSettingSemester",
            "deleteSemester",
        ]),
        open() {
            this.$refs.dialog.open();
        },
        onSubmit() {
            this.$refs.observer.validate().then((success) => {
                if (success) {
                    if (this.semesterDto.id) {
                        this.modifySemester(this.semesterDto);
                        this.getSettingSemester();
                    } else {
                        this.addSemester(this.semesterDto);
                        this.getSettingSemester();
                    }
                    this.$refs.dialog.close();
                }
            });
        },
        resetForm() {
            this.$refs.observer.reset();
            this.$store.commit("Set_Semester_Dto");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },

        deleteSem(id) {
            this.deleteSemester(id);
        },
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>
