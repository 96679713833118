<template>
    <div>
        <b-card>
            <ValidationObserver ref="form">
                <b-row>
                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'نسبة نقطة البيع مطلوبة ',
                                },
                            ]"
                            name="sellPointRate"
                            placeholder="أدخل نسبة نقطة البيع"
                            label="نسبة نقطة البيع "
                            v-model="settingDto.sellPointRate"
                        >
                        </ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'اسم المستخدم مطلوب',
                                },
                            ]"
                            name="answerPrice"
                            placeholder="أدخل قيمة الجواب لمجيبو الاسئلة "
                            label="قيمة الجواب لمجيبو الاسئلة "
                            v-model="settingDto.answerPrice"
                        >
                        </ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'نسبة الاساتذة مطلوبة',
                                },
                            ]"
                            name="teacherRate "
                            placeholder="ادخل نسبة الاساتذة"
                            label="نسبة الاساتذة "
                            v-model="settingDto.teacherRate"
                        >
                        </ek-input-text>
                    </b-col>
                </b-row>
                <b-row> </b-row>
            </ValidationObserver>
            <template #footer>
                <div class="w-100 d-flex justify-content-between">
                    <div>
                        <b-button variant="primary" @click="submit"
                            >تعديل</b-button
                        >
                    </div>
                </div>
            </template>
        </b-card>
        <b-card>
            <b-col cols="12" md="6" lg="6">
                <ek-table
                    :items="semesterList"
                    selectedLabel="id"
                    title="الفصول"
                    isPlus
                    :columns="semesterCols"
                    @plus="setSemesterDialogForm()"
                    @details="setSemesterDialogForm($event)"
                    @delete-selected="deleteSemesterAll"
                >
                </ek-table>
            </b-col>
        </b-card>
        <b-card>
            <div class="d-flex justify-content-between">
                <b-card-header>الألوان إلافتراضية</b-card-header>
                <b-button variant="outline-primary" @click="getDefulteColor"
                    >العودة للالوان الافتراضية</b-button
                >
            </div>
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    lg="2"
                    v-for="(colorGroup, index) in settingDto.colors"
                    :key="index"
                >
                    <label style="position: relative; top: 12px">
                        اللون {{ index + 1 }}
                    </label>
                    <div class="d-flex justify-content-end">
                        <b-button
                            variant="transparnt"
                            class="p-0"
                            @click="editColor"
                        >
                            <unicon
                                name="edit-alt"
                                fill="gray"
                                width="18px"
                            ></unicon>
                        </b-button>
                        <b-button
                            variant="transparnt"
                            class="p-0"
                            @click="deletecolor(index)"
                        >
                            <unicon
                                name="trash-alt"
                                fill="red"
                                width="18px"
                            ></unicon>
                        </b-button>
                    </div>
                    <b-form-group
                        v-for="(color, i) in colorGroup"
                        :key="i"
                        label-for="اللون"
                        style="font-weight: bold"
                    >
                        <div
                            class="d-flex"
                            style="
                                justify-content: space-between;
                                border: 1px solid rgb(208, 208, 206);
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                                border-top-right-radius: 10px;
                                border-top-left-radius: 10px;
                            "
                        >
                            <h6 class="labelColor" :style="`color:${color}`">
                                {{ color }}
                            </h6>
                            <b-col cols="5">
                                <b-input-group>
                                    <b-form-input
                                        style="
                                            position: relative;
                                            right: 14px;
                                            border-top-right-radius: 0px;
                                            border-bottom-right-radius: 0px;
                                        "
                                        :rules="[{ type: 'required' }]"
                                        type="color"
                                        name="اللون"
                                        v-model="colorGroup[i]"
                                    />
                                </b-input-group>
                            </b-col>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                    <h6 style="padding-bottom: 20px">لون جديد</h6>
                    <div style="display: grid">
                        <b-button variant="outline-primary" @click="addColor1">
                            إضافة تدرج اول
                        </b-button>
                        <b-button
                            variant="primary"
                            class="mt-1"
                            @click="addColor2"
                        >
                            إضافة تدرج ثاني
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <semester ref="semesterDialog" />
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import semester from "@admin/settings/components/AddSamester.vue";

export default {
    components: {
        semester,
    },
    computed: {
        ...mapState({
            settingDto: ({ settings }) => settings.settingDto,
            awsAccessDto: ({ settings }) => settings.awsAccessDto,
            semesterList: ({ settings }) => settings.semesterList.semesters,
            semesterCols: ({ settings }) => settings.semesterCols,
            isDialogOpen: (state) => state.settings.isDialogOpen,
        }),
    },
    methods: {
        ...mapActions([
            "getSettings",
            "updateSettings",
            "setDefaultColor",
            "getAwsAccess",
            "getSettingSemester",
            "deleteSemesterAll",
            "getSemesterById"
        ]),
        //open dialog semesters
        setSemesterDialogForm(item) {
            if (!item) {
                this.$store.commit("Set_Semester_Dto");
            } else {
                this.getSemesterById(item.row.id)    .then(() => {
                // Once the semester details are fetched, set them in the store
                this.$store.commit("SET_DETAIL_Semester", item.row);
                // Open the dialog
                this.$store.commit("IS_DIALOG_OPEN", true);
            })
            }
            // this.$refs.semesterDialog.open();
            this.$store.commit("IS_DIALOG_OPEN", true);
        },
        
        submit() {
            this.$refs["form"].validate().then((suc) => {
                if (suc) {
                    this.updateSettings(this.settingDto);
                }
            });
        },
        getDefulteColor() {
            return this.setDefaultColor();
        },
        editColor() {
            return this.updateSettings(this.settingDto);
        },
        deletecolor(index) {
            this.settingDto.colors.splice(index, 1);
        },
        addColor1() {
            this.settingDto.colors.push(["#ffffff"]);
        },
        addColor2() {
            let l = this.settingDto.colors.length - 1;
            if (this.settingDto.colors[l].length < 2)
                this.settingDto.colors[this.settingDto.colors.length - 1].push(
                    "#ffffff"
                );
        },
    },
    created() {
        this.getSettings();
        this.getAwsAccess();
        this.getSettingSemester();
        // this.getSemesterById()
    },
};
</script>

<style>
.labelColor {
    padding: 7px !important;
}
</style>
